/**
 * @module Template - Default
 * @description Default Template
 */
import PropTypes from 'prop-types';

// Partials
import Header from '@/blocks/Header';
import Footer from '@/blocks/Footer';

// WordPress
import useWordPressLegal from '@/providers/wordpress/hooks/useWordPressLegal';

/**
 * @function Template
 * @description
 */
const Template = ( {
  title, hero, primary, modal
} ) => {
  const [ ref ] = useWordPressLegal();
  return (
    <>
      <Header />

      <div ref={ ref } id="primary" className="content-area">
        <main id="content" className="site-content" role="main" tabIndex="-1">
          <article id="post-id" className="hentry entry page-entry">
            <header className="entry-header" suppressHydrationWarning={ true }>
              <h1 className="entry-title screen-reader-text">{ title }</h1>
              { hero }
            </header>

            <div className="entry-content" suppressHydrationWarning={ true }>
              { primary }
            </div>
          </article>
        </main>

        { modal }
      </div>{ /* <!-- .content-area --> */ }

      <Footer />
    </>
  );
};

Template.propTypes = {
  title: PropTypes.string.isRequired,
  hero: PropTypes.object,
  primary: PropTypes.object.isRequired,
  modal: PropTypes.object
};

export default Template;
