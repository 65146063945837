/**
 * @module StickyNav
 * @description Global Sticky Nav
 */
import {
  useState, useEffect, useContext, useCallback
} from 'react';
import Link from 'next/link';
import { useAgentStatus } from '@/hooks/useAgentStatus';
import countdown from '@/js/utils/countdown';
import { stringify } from '@/js/utils/filters';
import { FuelContext } from '@/providers/fuel-provider';
import { trackEvent } from '@/js/helpers/tracking';
import styles from './index.module.css';
import { CohesionContext } from '@red-digital/bricks';
import { formatPhoneNumber } from '@/js/helpers/fuse';

/**
 * @function Component
 * @description
 */
const Component = () => {
  const [ isOpen, setIsOpen ] = useState( true );
  const [ time, setTime ] = useState( null );
  const { cartHeavy } = useContext( FuelContext );
  const { defaultFuseNumber, fuseNumber } = useContext( CohesionContext );
  const phoneNumber = formatPhoneNumber( fuseNumber || defaultFuseNumber );
  const fetchStatus = useAgentStatus( );

  /**
   * @function handleClick
   * @description handle tagular tracking
   */
  const handleClick = ( event ) => {
    const { target } = event;
    const { text, actionOutcome } = JSON.parse( target.getAttribute( 'data-payload' ) );
    const data = {
      elementType: 'BUTTON',
      location: 'GLOBAL',
      position: 'HEADER',
      text,
      actionOutcome
    };

    trackEvent( { action: 'elementClicked', data, event } );
  };

  /**
   * @function initSticky
   * @description Begin sticky nav
   */
  const initSticky = useCallback( () => {
    const primary = document.querySelector( '#primary' );
    const navbar = document.querySelector( '.site-header' );

    if ( primary ) primary.style.paddingTop = `${ navbar.clientHeight }px`;
  }, [] );

  /**
   * @function useEffect
   * @description Kick off this modules functions
   */
  useEffect( () => {
    const data = fetchStatus();
    setIsOpen( data?.isOpen );
    initSticky();

    if ( ! data?.isOpen ) return;

    const { minute, hour, second } = data.closeTime;

    const endDate = new Date();
    endDate.setHours( parseInt( hour, 10 ), parseInt( minute, 10 ), parseInt( second, 10 ) );

    countdown( endDate.getTime(), setTime );

    window.addEventListener( 'resize', initSticky );
  }, [ fetchStatus, initSticky, isOpen ] );

  useEffect( () => {
    initSticky();
  }, [ cartHeavy, initSticky ] );

  return <>
    { isOpen && cartHeavy === false && (
      <section className={ `u-theme-dark ${ styles.component } ` } data-sticky-nav data-is-open={ isOpen ? 'true' : 'false' }>
        <div className="l-container">
          <div className={ styles.component__content }>
            <p className="lead show-for-large">
              We&apos;re open! <span className="icon icon-clock"></span>
              { time && (
                <>
                  <span> { time.hours } { time.hours === 1 ? 'hour' : 'hours' } </span>
                  <span>{ time.minutes } { time.minutes === 1 ? 'minute' : 'minutes' } </span>
                  <span>{ time.seconds } { time.seconds === 1 ? 'second' : 'seconds' } </span>
                </>
              )}
            </p>

            <p className={ `${ styles.component__phone } lead` }>
              <Link
                href={ `tel:${ phoneNumber }` }
                onClick={ handleClick }
                data-payload={ stringify( {
                  text: 'Phone Number',
                  actionOutcome: 'CLICKTOCALL'
                } ) }>
                Call now<span className="icon icon-phone"></span> <em>{ phoneNumber }</em>
              </Link>
            </p>
          </div>
        </div>
      </section>/* <!-- .sticky-nav --> */
    ) }
  </>;
};

export default Component;
